<template>
  <page-layout :retailer-id="retailerId" active-menu-item="dashboard">
    <validation-observer ref="observer">
      <main-column :breadcrumbs="breadcrumbs" :page-title="pageTitle" no-banner>

        <b-notification :active="errorMessage != null" :message="errorMessage" type="is-danger"
          :closable="false"></b-notification>
        <div class="columns">
          <div class="column is-half">
            <div class="card mb-4">
              <div class="card-content">
                <h2 class="title is-2">Retailer Info</h2>
                <table class="table is-fullwidth stats-table" v-if="item.store">
                  <tbody>
                    <tr>
                      <td>
                        Name:
                      </td>
                      <td class="has-text-right">{{item.name}}</td>
                    </tr>
                  </tbody>
                </table>
                <b-button :to="dashboardPath" type="is-primary is-rounded" tag="router-link">View Dashboard</b-button>
              </div>
            </div>
            <div class="card" v-if="item.billing_scheme">
              <div class="card-content">
                <h2 class="title is-2">Billing Info</h2>
                <table class="table is-fullwidth stats-table">
                  <tbody>
                    <tr>
                      <td>
                        Pricing Plan
                      </td>
                      <td class="has-text-right">{{ item.billing_scheme.name }}</td>
                    </tr>
                    <tr>
                      <td>
                        Current Billing Period Started
                      </td>
                      <td class="has-text-right">{{ billingCycleStart }}</td>
                    </tr>
                    <tr>
                      <td>
                        Current Billing Period Ends
                      </td>
                      <td class="has-text-right">{{ billingCycleEnd }}</td>
                    </tr>
                    <tr>
                      <td>Billing Cycle Usage Fee Cap</td>
                      <td class="has-text-right">{{ item.billing_scheme.billing_cycle_usage_cap_with_currency }}</td>
                    </tr>
                    <tr>
                      <td>Billed This Cycle</td>
                      <td class="has-text-right">{{ item.billing_scheme.current_cycle_billed_fees }}</td>
                    </tr>
                    <tr>
                      <td>Unbilled Usage Fees</td>
                      <td class="has-text-right"><strong>{{ item.billing_scheme.unbilled_usage_fees }}</strong></td>
                    </tr>
                  </tbody>
                </table>
                <b-button :to="planPath" type="is-secondary is-rounded" tag="router-link">Manage Plan</b-button>&nbsp;
                <b-button :to="usagePath" type="is-secondary is-rounded" tag="router-link">Manage Charges</b-button>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="card mb-4">
              <div class="card-content">
                <h2 class="title is-2">Store Info</h2>
                <table class="table is-fullwidth stats-table" v-if="item.store">
                  <tbody>
                    <tr>
                      <td>
                        Platform:
                      </td>
                      <td class="has-text-right">{{storePlatform()}}</td>
                    </tr>
                    <tr v-if="storePlatform() == 'shopify'">
                      <td>
                        MyShopify Domain:
                      </td>
                      <td class="has-text-right">
                        <a :href="'https://' + item.store.myshopify_domain"
                          target="_new">{{item.store.myshopify_domain}}</a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Primary Domain:
                      </td>
                      <td class="has-text-right">
                        <a :href="'https://' + item.store.primary_domain"
                          target="_new">{{item.store.primary_domain}}</a>
                      </td>
                    </tr>
                    <tr v-if="storePlatform() == 'shopify'">
                      <td>Shopify Discount Functions Enabled:</td>
                      <td class="has-text-right">
                        <b-switch :value="item.store.discount_functions_enabled" @input="toggleShopifyDiscountFunctions($event)"></b-switch>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <b-button v-if="storePlatform() == 'shopify'" :to="shopifyStorePath" type="is-primary is-rounded"
                  tag="router-link">Edit Shopify Integration</b-button>
              </div>
            </div>
            <div class="card">
              <div class="card-content">
                <h2 class="title is-2">Uplift A/B Test</h2>
                <table class="table is-fullwidth stats-table">
                  <tbody>
                    <tr>
                      <td>Uplift Test Status</td>
                      <td class="has-text-right"><strong>{{item.uplift_test_status}}</strong></td>
                    </tr>
                    <tr v-if="item.uplift_test_rule">
                      <td>Uplift Test Start Date</td>
                      <td class="has-text-right">{{item.uplift_test_rule.created_at}}</td>
                    </tr>
                    <tr v-if="item.uplift_test_rule">
                      <td>Cohorts</td>
                      <td class="has-text-right">{{item.uplift_test_rule.summary}}</td>
                    </tr>
                  </tbody>
                </table>
                <b-button v-if="item.uplift_test_rule" @click="stopUpliftTest" type="is-secondary is-rounded">Terminate
                  Uplift Test</b-button>
                <form @submit="startUpliftTest" v-else>
                  <fieldset>
                    <div class="field has-addons">
                      <b-select v-model="upliftControlPercentage" placeholder="Control Percentage">
                        <option value="5">5%</option>
                        <option value="10">10%</option>
                        <option value="15">15%</option>
                        <option value="20">20%</option>
                        <option value="25">25%</option>
                        <option value="30">30%</option>
                        <option value="35">35%</option>
                        <option value="40">40%</option>
                        <option value="45">45%</option>
                        <option value="50">50%</option>
                      </b-select>
                      <div class="control">
                        <a class="button is-primary is-rounded" @click="startUpliftTest">
                          Start New Test
                        </a>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main-column>
    </validation-observer>
  </page-layout>
</template>

<script>
import moment from 'moment'
import nibble from '@/utils/nibble'
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import ItemEditor from '@/utils/mixins/ItemEditor'

export default {
  name: 'Retailer',
  mixins: [ItemEditor],
  components: { PageLayout, MainColumn },
  data () {
    return {
      item: {
        name: ''
      },
      upliftControlPercentage: null
    }
  },
  computed: {
    billingCycleStart () {
      const start = this.item.billing_scheme.last_billing_cycle_ended_at || this.item.billing_scheme.billing_cycle_started_at
      if (start != null) {
        return moment(start).format('DD/MM/YYYY')
      } else {
        return 'N/A'
      }
    },
    billingCycleEnd () {
      const end = this.item.billing_scheme.current_billing_cycle_ends_at
      if (end != null) {
        return moment(end).format('DD/MM/YYYY')
      } else {
        return 'N/A'
      }
    },
    pageTitle () {
      if (this.item != null) {
        return this.item.name
      } else {
        return 'Retailer Info'
      }
    },
    listPath () {
      return '/retailers/'
    },
    newPath () {
      return '/retailers/new'
    },
    itemPath () {
      return `/retailers/${this.retailerId}`
    },
    listApiPath () {
      return '/retailers'
    },
    itemApiPath () {
      return `/retailers/${this.retailerId}`
    },
    itemApiParams () {
      return { context: 'admin' }
    },
    itemName () {
      return 'Retailer'
    },
    itemsName () {
      return 'Retailers'
    },
    dashboardPath () {
      return `/retailers/${this.retailerId}/dashboard`
    },
    nibblesPath () {
      return `/retailers/${this.retailerId}/sessions`
    },
    shopifyStorePath () {
      return `/retailers/${this.retailerId}/shopify_store`
    },
    planPath () {
      return `/retailers/${this.retailerId}/account/plan`
    },
    usagePath () {
      return `/retailers/${this.retailerId}/account/usage`
    }
  },
  methods: {
    storePlatform () {
      return this.item !== null && this.item.store ? this.item.store.platform : null
    },
    startUpliftTest () {
      const params = {
        control_cohort_percentage: this.upliftControlPercentage
      }
      if (!this.upliftControlPercentage) {
        this.errorMessage = 'Please select a control percentage'
        return
      }
      this.errorMessage = null
      nibble.post(`/retailers/${this.retailerId}/uplift_test`, params, this.$store.getters.authHeaders)
        .then((response) => {
          this.fetchItemData()
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.errorMessage = error.response.data.errorMessage
          } else {
            nibble.handleNetworkError(error, this.$store)
          }
        })
    },
    stopUpliftTest () {
      nibble.delete(`/retailers/${this.retailerId}/uplift_test`, {}, this.$store.getters.authHeaders)
        .then((response) => {
          this.fetchItemData()
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    toggleShopifyDiscountFunctions () {
      const params = {
        discount_functions_enabled: !this.item.store.discount_functions_enabled
      }
      nibble.put(`/retailers/${this.retailerId}/shopify_store`, params, this.$store.getters.authHeaders)
        .then((response) => {
          this.fetchItemData()
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
