import { ToastProgrammatic as Toast } from 'buefy'
import nibble from '@/utils/nibble'

export default {
  created () {
    this.routeUpdated()
  },
  computed: {
    itemIdsKey () {
      return `${this.itemIdKey}s`
    },
    itemsName () {
      return `${this.itemName}s`
    },
    listApiParams () {
      return {}
    },
    breadcrumbs () {
      return [
        {
          link: this.listPath,
          title: this.itemsName
        }
      ]
    }
  },
  watch: {
    $route: 'routeUpdated'
  },
  methods: {
    routeUpdated () {
      this.fetchData()
    },
    itemPath (item) {
      return this.listPath + '/' + item[this.itemIdKey]
    },
    itemApiPath (item) {
      return this.listApiPath + '/' + item[this.itemIdKey]
    },
    fetchData () {
      nibble.get(this.listApiPath, this.listApiParams, this.$store.getters.authHeaders)
        .then((response) => {
          console.log(response)
          this.items = response.data[this.itemsKey]
          this.totalItemCount = response.data.total || response.data.total_count || response.data.count
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    clickRow (item) {
      this.editItem(item)
    },
    editItem (item) {
      this.$router.push(this.itemPath(item))
    },
    deleteItem (item) {
      nibble.delete(this.itemApiPath(item), {}, this.$store.getters.authHeaders)
        .then((response) => {
          Toast.open(`${this.itemName} Deleted`, { type: 'is-primary' })
          this.fetchData()
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    deleteConfirmationMessage (itemCount) {
      if (itemCount > 1) {
        return `Are you sure you want to delete ${itemCount} ${this.itemsName}? This action cannot be undone.`
      } else {
        return `Are you sure you want to delete this ${this.itemName}? This action cannot be undone.`
      }
    },
    confirmDeleteBatch () {
      this.$buefy.dialog.confirm({
        message: this.deleteConfirmationMessage(this.selectedItems.length),
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteBatch()
      })
    },
    deleteBatch () {
      if (this.selectedItems.length > 0) {
        var params = {}
        params[this.itemIdsKey] = this.selectedItems.map((item) => item[this.itemIdKey])

        nibble.delete(this.listApiPath, params, this.$store.getters.authHeaders)
          .then((response) => {
            if (this.selectedItems.length === 1) {
              Toast.open(`${this.itemName} Deleted`, { type: 'is-primary' })
            } else {
              Toast.open(`${this.itemsName} Deleted`, { type: 'is-primary' })
            }
            this.fetchData()
          })
          .catch((error) => {
            nibble.handleNetworkError(error, this.$store)
          })
      }
    }
  }
}
