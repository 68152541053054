<template>
  <div>
    <hr>
    <table class="table is-fullwidth stats-table">
      <tbody>
        <tr>
          <th>Cohort</th>
          <th>Size</th>
          <th>Conversions</th>
          <th>Conversion Rate</th>
          <th>P-Value</th>
          <th>Improvement</th>
        </tr>
        <tr>
          <td>Control</td>
          <td>{{ controlSessions }}</td>
          <td>{{ controlSales }}</td>
          <td>
            {{ formatPercentage(results.control.interval.lower) }} to
            {{ formatPercentage(results.control.interval.upper) }}
            (<strong>{{ formatPercentage(results.control.interval.base) }}</strong>)
          </td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>Test</td>
          <td>{{ testSessions }}</td>
          <td>{{ testSales }}</td>
          <td>
            {{ formatPercentage(results.test.interval.lower) }} to
            {{ formatPercentage(results.test.interval.upper) }}
            (<strong>{{ formatPercentage(results.test.interval.base) }}</strong>)
          </td>
          <td><strong>{{ results.test.pValue.toFixed(3) }}</strong></td>
          <td>
            {{ formatPercentage(results.test.improvement.lower) }} to
            {{ formatPercentage(results.test.improvement.upper) }}
            (<strong>{{ formatPercentage(results.test.improvement.base) }}</strong>)
          </td>
        </tr>
      </tbody>
    </table>
    <p class="has-text-centered" v-if="results && results.test.significant">
      <strong>Conversion results are statistically significant (p &le; {{ significanceThreshold }})</strong>
    </p>
    <p class="has-text-centered" v-else-if="results">
      Conversion results are <strong>not</strong> statistically significant (p &le; {{ significanceThreshold }})
    </p>
  </div>
</template>

<script>
import Abba from '@/utils/abba'

export default {
  name: 'ConversionSignificance',
  components: {},
  props: {
    controlSales: {
      required: true,
      type: Number
    },
    controlSessions: {
      required: true,
      type: Number
    },
    testSales: {
      required: true,
      type: Number
    },
    testSessions: {
      required: true,
      type: Number
    }
  },
  computed: {
    significanceThreshold () {
      return 0.05
    },
    results () {
      const experiment = new Abba.Experiment(1, this.controlSales, this.controlSessions, this.significanceThreshold)
      const results = experiment.getResults(this.testSales, this.testSessions)
      const baseline = experiment.getBaselineProportion()
      const output = {
        control: {
          interval: {
            lower: Math.min(1, Math.max(0, baseline.lowerBound)),
            upper: Math.min(1, Math.max(0, baseline.upperBound)),
            base: baseline.value
          }
        },
        test: {
          interval: {
            lower: Math.min(1, Math.max(0, results.proportion.lowerBound)),
            upper: Math.min(1, Math.max(0, results.proportion.upperBound)),
            base: results.proportion.value
          },
          pValue: results.pValue,
          significant: (results.pValue < this.significanceThreshold),
          improvement: {
            lower: results.relativeImprovement.lowerBound,
            upper: results.relativeImprovement.upperBound,
            base: results.relativeImprovement.value
          }
        }
      }
      return output
    }
  },
  methods: {
    formatPercentage (num) {
      if (num == null) {
        return '-'
      }
      return (num * 100).toFixed(2) + '%'
    }
  }
}
</script>
