<template>
  <page-layout :active-menu-item="menuItem">
    <main-column :breadcrumbs="breadcrumbs" :page-title="pageTitle">
      <template #header-right>
        <p class="level-item buttons" v-if="isEditable">
          <b-button type="is-text is-rounded" tag="router-link" :to="listPath">Cancel</b-button>
          <b-button type="is-primary is-rounded" @click="updateExperiment" v-if="!item.archived">Save</b-button>
        </p>
      </template>

      <notification-bar v-if="errorSection" type="is-danger">
        Unable to save form: {{ errorMessage }}.
      </notification-bar>
      <div v-if="isEditable">
        <ValidationObserver ref="general_experiment_observer" slim>
          <div class="card">
            <div class="card-content">
              <h2 class="title is-2">General Settings</h2>
              <div class="columns is-multiline">
                <div class="column is-full">
                  <ValidationProvider rules="required" name="Experiment Name" v-slot="{ errors }">
                    <b-field label="The Experiment name as it will appear in your Experiments List" :message="errors" :type="{ 'is-danger': errors[0] }">
                      <b-input v-model="item.name"></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-full">
                  <ValidationProvider rules="" name="Experiment Description" v-slot="{ errors }">
                    <b-field label="Add a short description to this Experiment" :message="errors" :type="{ 'is-danger': errors[0] }">
                      <textarea class="textarea" v-model="item.description" rows="3" max-rows="6" placeholder="Enter something..."></textarea>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="columns is-multiline">
                <div class="column is-full timing">
                  <h3 class="title is-3">Timing</h3>
                  <p>This data will be filled automatically when the Experiment will be switched on and then off</p>
                </div>
                <div class="column is-half">
                  <p>
                    Begin Date (activation time)
                    <br/>
                    {{startDate}}
                  </p>
                </div>
                <div class="column is-half">
                  <p>
                    End Date (deactivation time)
                    <br/>
                    {{endDate}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ValidationObserver>
        <br/>
        <ValidationObserver ref="retailers_experiment_observer" slim>
          <div class="card">
            <div class="card-content">
              <h2 class="title is-2">Applicability</h2>
              <p>Which retailers will be affected by this Experiment? <strong>Leave this list empty to affect ALL Retailers.</strong></p>
              <br/>
              <div class="columns">
                <div class="column is-two-thirds">
                  <ValidationProvider name="Retailer" v-slot="{ errors }">
                    <b-field label="" :message="errors" :type="{ 'is-danger': errors[0] }">
                      <retailer-list v-model="item.retailers" :disabled="!isEditable"></retailer-list>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="columns">
                <div class="column is-half">
                  <b-field label="Apply to which Objective?">
                    <b-select v-model="item.objective" expanded>
                      <option :value="null">Any</option>
                      <option value="increase_conversion">Increase conversion</option>
                      <option value="balance_performance">Balance Performance</option>
                      <option value="preserve_margin">Preserve Margin</option>
                    </b-select>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </ValidationObserver>
        <br/>
        <ValidationObserver ref="cohorts_experiment_observer" slim>
          <div v-for="(cohort, cindex) in item.cohorts" :key="cindex">
            <div class="card">
              <div class="card-content contrast">
                <div class="columns is-multiline">
                  <div class="column is-three-quarters"><h2 class="title is-2">Cohort {{cindex}}</h2></div>
                  <div class="column has-text-right"><span class="nibble-icon material-icons-round icon-large is-clickable  " v-if="isEditable && cindex > 0" @click="removeCohort(cindex)">delete_forever</span></div>
                </div>
                <div class="columns is-multiline">
                  <div class="column is-full">
                    <ValidationProvider rules="required" name="Cohort Name" v-slot="{ errors }">
                      <b-field class="is-full" label="Cohort Name" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <b-input v-model="cohort.name" @input="refresh = Date.now()" :disabled="!isEditable"></b-input>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-half">
                    <ValidationProvider rules="required" name="Cohort Percentage" v-slot="{ errors }">
                      <b-field class="is-half" label="Cohort Percentage" :message="errors" :type="{ 'is-danger': errors[0] }">
                        <p class="control">
                          <span class="button is-static">%</span>
                        </p>
                        <b-input v-model.number="cohort.cohort_percentage" @input="refresh = Date.now()" :disabled="!isEditable"></b-input>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-half">
                    <b-field class="is-half" label="Control Percentage">
                      <p class="control">
                        <span class="button is-static">%</span>
                      </p>
                      <b-input v-model.number="control_percentage" disabled="true"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-half">
                    <b-field label="Nibble Display Override?">
                      <b-select v-model="cohort.nibble_override_setting" placeholder="Select an Option" expanded>
                        <option value="no_change">No Change</option>
                        <option value="disable">Force Disabled</option>
                      </b-select>
                    </b-field>
                  </div>
                  <div class="column is-half">
                    <b-field label="Theme Override">
                      <b-select v-model="cohort.override_theme_id" expanded>
                        <option :value="null">No Change</option>
                        <option v-for="theme in availableThemes" :value="theme.theme_id" :key="theme.theme_id">{{theme.name}}</option>
                      </b-select>
                    </b-field>
                  </div>
                  <div class="column is-full">
                    <h3 class="title is-3">Algorithm Settings</h3>
                    <div class="columns">
                      <div class="column is-half">
                        <b-field label="Algorithm Version">
                          <b-select v-model="cohort.algorithm_version" expanded>
                            <option :value="null">No Change</option>
                            <option value="a_baseline">a_baseline</option>
                            <option value="b_tit_for_tat">b_tit_for_tat</option>
                            <option value="c_exponential">c_exponential</option>
                          </b-select>
                        </b-field>
                      </div>
                      <div class="column is-half" v-if="cohort.algorithm_version != null">
                        <b-field label="Target Discount (as a percentage of discount threshold)">
                          <p class="control">
                            <span class="button is-static" disabled>%</span>
                          </p>
                          <b-input v-model="cohort.target_discount_percentage" />
                        </b-field>
                      </div>
                    </div>
                    <div class="columns" v-if="cohort.algorithm_version != null">
                      <div class="column is-full">
                        <b-field label="Hyper Parameters (JSON)">
                          <textarea class="textarea" v-model="cohort.hyper_parameters" rows="3" max-rows="6" placeholder="{...}" :disabled="!isEditable"></textarea>
                        </b-field>
                      </div>
                    </div>
                  </div>
                  <div class="column is-full">
                    <h3 class="title is-3">Features</h3>
                    <p>Select which features are enabled for this cohort</p>
                  </div>
                  <div class="column is-full cohort_features" v-for="feature in cohort.cohort_features" :key="feature.id">
                    <h4 class="title is-4">{{feature.name}}</h4>
                    <div class="columns">
                      <div class="column is-half">
                        <b-field label="Active?">
                          <b-select v-model="feature.feature_override_setting" placeholder="Select an Option" expanded>
                            <option value="no_change">No Change</option>
                            <option value="enable">Force Enabled</option>
                            <option value="disable">Force Disabled</option>
                          </b-select>
                        </b-field>
                      </div>
                      <!-- <div class="column is-half">
                        <b-field label="Feature Hyper Parameters (JSON)">
                          <textarea class="textarea" v-model="feature.hyper_parameters" rows="3" max-rows="6" placeholder="{...}" :disabled="!isEditable"></textarea>
                        </b-field>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br/>
          </div>
        </ValidationObserver>
        <p class="has-text-centered">
          <b-button type="button is-rounded" @click="addCohort()">+ Add Cohort</b-button>
        </p>
      </div> <!-- End editable -->
      <div v-else>
        <div class="card">
          <div class="card-content">
            <h2 class="title is-2">Experiment Info</h2>
            <p>{{ item.description }}</p>
            <br>
            <div class="columns is-multiline">
              <div class="column is-half">
                <p>
                  Begin Date (activation time)
                  <br/>
                  {{startDate}}
                </p>
              </div>
              <div class="column is-half">
                <p>
                  End Date (deactivation time)
                  <br/>
                  {{endDate}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <br/>
        <div class="card">
          <div class="card-content">
            <h2 class="title is-2 mb-0">Results</h2>
            <p>Results are updated once per day.</p>
            <br>
            <table class="table is-fullwidth stats-table">
              <tbody>
                <tr>
                  <th>&nbsp;</th>
                  <th colspan="4">Overall User Carts</th>
                  <th colspan="4">Nibble Negotiations</th>
                </tr>
                <tr>
                  <th style="border-right: 1px solid #D5DDE5;">Cohort</th>
                  <th>Users</th>
                  <th>Total Orders</th>
                  <th>Total Order Value</th>
                  <th style="border-right: 1px solid #D5DDE5;">AOV</th>
                  <th>Chats</th>
                  <th>Agreed Deals</th>
                  <th>Sold</th>
                  <th>Total Value</th>
                </tr>
                <tr v-for="(cohort, cindex) in cohortStats" :key="cindex">
                  <td style="border-right: 1px solid #D5DDE5;">{{ cohort.name }}</td>
                  <td>{{ cohort.browsers.sessions }}</td>
                  <td>{{ cohort.browsers.sales }}</td>
                  <td>{{ cohort.browsers.revenue }}</td>
                  <td style="border-right: 1px solid #D5DDE5;">{{ cohort.browsers.aov }}</td>
                  <td>{{ cohort.chats.sessions }}</td>
                  <td>{{ cohort.chats.agreedDeals }}</td>
                  <td>{{ cohort.chats.sales }}</td>
                  <td>{{ cohort.chats.revenue }}</td>
                </tr>
              </tbody>
            </table>
            <p v-if="statsLoading">Results are loading, please wait...</p>
            <div v-else>
              <h4 class="title is-4 mb-0">Notes</h4>
              <p>
                Users are divided into cohorts based on the specified percentages. Cohort membership
                is recorded in a persistent cookie, so returning users usually remain in the same cohort.
              </p>
              <br>
              <p>
                <strong>Overall User Cart</strong> statistics measure all users who have interacted with the retailer's website,
                including those who have not interacted with Nibble at all. These statistics are useful for uplift testing.
              </p>
              <br>
              <p>
                <strong>Nibble Negotiation</strong> statistics measure individual Nibble negotiation chat sessions. A single
                order may include multiple sold items from multiple Nibble chats.
              </p>
            </div>
          </div>
        </div>
        <br>
        <div class="card" v-if="!statsLoading && cohortStats.length == 2">
          <div class="card-content">
            <h2 class="title is-2">Conversion Significance Measures</h2>
            <h4 class="title is-4">Overall User Carts</h4>
            <conversion-significance
              :control-sales="cohortStats[0].browsers.sales"
              :control-sessions="cohortStats[0].browsers.sessions"
              :test-sales="cohortStats[1].browsers.sales"
              :test-sessions="cohortStats[1].browsers.sessions"/>
            <hr>
            <br>
            <h4 class="title is-4">Nibble Negotiations :: Agreed Deals</h4>
            <conversion-significance
              :control-sales="cohortStats[0].chats.agreedDeals"
              :control-sessions="cohortStats[0].chats.sessions"
              :test-sales="cohortStats[1].chats.agreedDeals"
              :test-sessions="cohortStats[1].chats.sessions"/>
            <hr>
            <br>
            <h4 class="title is-4">Nibble Negotiations :: Sales</h4>
            <conversion-significance
              :control-sales="cohortStats[0].chats.sales"
              :control-sessions="cohortStats[0].chats.sessions"
              :test-sales="cohortStats[1].chats.sales"
              :test-sessions="cohortStats[1].chats.sessions"/>
            <hr>
            <br>
            <p>
              Significance values are calculated with <a href="https://github.com/thumbtack/abba">abba</a>
            </p>
          </div>
        </div>
      </div>
      <br/>
    </main-column>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout'
import MainColumn from '@/components/MainColumn'
import ItemEditor from '@/utils/mixins/ItemEditor'
import nibble from '@/utils/nibble'
import NotificationBar from '@/components/NotificationBar'
import RetailerList from '@/components/product-search/RetailerList'
import ConversionSignificance from '@/components/analytics/ConversionSignificance'

function fixDate (d = '') {
  if (d) {
    d = d.replaceAll('-', '/')
    d = d.replaceAll('T', ' ')
  } else {
    d = '-'
  }
  return d
}

export default {
  name: 'Experiment',
  mixins: [ItemEditor],
  components: { PageLayout, MainColumn, NotificationBar, RetailerList, ConversionSignificance },
  data () {
    return {
      refresh: false, // used to watch the input events to rebuild the control_percentage
      context: this.$route.query.context || 'experiments',
      control_percentage_max: 100,
      control_percentage: null,
      availableFeatures: [],
      availableThemes: [],
      retailers: [],
      item: {
        starts_at: null,
        ends_at: null,
        experiment_id: null,
        name: null,
        description: '',
        active: false,
        deleted: false,
        objective: null,
        retailers: [],
        cohorts: [
          {
            cohort_percentage: 50,
            name: 'New Cohort',
            nibble_override_setting: 'no_change',
            override_theme_id: null,
            algorithm_version: null,
            hyper_parameters: JSON.stringify({}),
            target_discount_percentage: null,
            cohort_features: [],
            cohort_id: null
          }
        ]
      },
      statsLoading: false,
      cohortStats: [],
      errorSection: null
    }
  },
  created () {
    this.control_percentage = this.controlPercentage()
    // this.fetchAvailableFeatures()
    // this.fetchAvailableThemes()
  },
  watch: {
    'item.cohorts' () {
      this.control_percentage = this.controlPercentage()
    },
    'refresh' () {
      this.control_percentage = this.controlPercentage()
    }
  },
  computed: {
    isEditable () {
      return !this.item.active && !this.item.archived
    },
    pageTitle () {
      if (this.isNewItem) {
        return `New ${this.itemName}`
      } else if (this.isEditable) {
        return `Edit ${this.itemName}`
      } else {
        return this.item.name
      }
    },
    startDate () {
      return fixDate(this.item.starts_at)
    },
    endDate () {
      return fixDate(this.item.ends_at)
    },
    menuItem () {
      return this.context
    },
    listPath () {
      if (this.context === 'experiments') {
        return '/admin/experiments'
      } else if (this.context === 'experiments-archive') {
        return '/admin/experiments-archive'
      } else {
        return null
      }
    },
    newPath () {
      return { path: '/admin/experiments/new', query: { context: this.context } }
    },
    itemPath () {
      return `/admin/experiments/${this.itemId}`
    },
    listApiPath () {
      return '/rules'
    },
    itemApiPath () {
      return `/rules/${this.itemId}`
    },
    itemName () {
      return 'Experiment'
    }
  },
  methods: {
    fetchAvailableFeatures () {
      // Using Bot_Test for now
      nibble.get('/features', {}, {})
        .then((response) => {
          for (const f of response.data.available_features) {
            this.availableFeatures.push({ id: f, name: f })
          }
          if (this.item.cohorts != null && this.item.cohorts.length === 1 && this.item.cohorts[0].cohort_features.length === 0) {
            for (const f of this.availableFeatures) {
              this.item.cohorts[0].cohort_features.push({
                id: f.id,
                name: f.name,
                feature_override_setting: 'no_change',
                hyper_parameters: JSON.stringify({})
              })
            }
          }
          // console.log(this.availableFeatures)
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    fetchAvailableThemes () {
      return nibble.get('/admin/themes', {}, this.$store.getters.authHeaders)
        .then((response) => {
          this.availableThemes = response.data.themes
        })
        .catch((error) => {
          nibble.handleNetworkError(error, this.$store)
        })
    },
    fetchOptions () {
      if (this.item.active || this.item.archived) {
        this.statsLoading = true
        this.fetchCohortStats(0)
      } else {
        this.fetchAvailableFeatures()
        this.fetchAvailableThemes()
      }
    },
    fetchCohortStats (cohortIndex) {
      if (cohortIndex <= this.item.cohorts.length - 1) {
        var query = { action: 'experiment_cohort', cohortId: this.item.cohorts[cohortIndex].cohort_id }
        return nibble.post('/stats', query, this.$store.getters.authHeaders)
          .then((response) => {
            this.cohortStats.push(response.data)
            this.fetchCohortStats(cohortIndex + 1)
          })
      } else {
        this.statsLoading = false
      }
    },
    controlPercentage () {
      var cohortsPercentages = 0
      for (const cohort of this.item.cohorts) {
        cohortsPercentages = cohortsPercentages + cohort.cohort_percentage
      }
      return Math.max(0, this.control_percentage_max - cohortsPercentages)
    },
    validateGeneralExperimentsSettings () {
      return this.$refs.general_experiment_observer.validate()
    },
    validateRetailersExperimentsSettings () {
      return this.$refs.retailers_experiment_observer.validate()
    },
    validateCohortsExperimentsSettings () {
      return this.$refs.cohorts_experiment_observer.validate()
    },
    itemToParams () {
      var params = {
        context: this.context,
        rule_id: this.item.experiment_id,
        name: this.item.name,
        description: this.item.description,
        cohorts: this.item.cohorts.map((c) => {
          return Object.assign({}, c,
            {
              hyper_parameters: JSON.parse(c.hyper_parameters || '{}'),
              cohort_features: c.cohort_features.map((f) => {
                return Object.assign({}, f, { hyper_parameters: JSON.parse(f.hyper_parameters || '{}') })
              })
            })
        }),
        objective: this.item.objective,
        retailers: this.item.retailers
      }
      return params
    },
    dataToItem (data) {
      var item = {
        name: data.name,
        description: data.description,
        cohorts: data.cohorts.map((c) => {
          return Object.assign({}, c,
            {
              hyper_parameters: JSON.stringify(c.hyper_parameters || {}),
              cohort_features: c.cohort_features.map((f) => {
                return Object.assign({}, f, { hyper_parameters: JSON.stringify(f.hyper_parameters || {}) })
              })
            })
        }),
        retailers: data.retailers,
        objective: data.objective,
        experiment_id: data.rule_id,
        starts_at: data.starts_at,
        ends_at: data.ends_at,
        active: data.active,
        archived: data.archived,
        deleted: data.deleted
      }
      console.log(item)
      return item
    },
    addCohort () {
      const n = JSON.parse(JSON.stringify(this.item.cohorts[0]))
      n.cohort_name = 'New Cohort'
      n.cohort_id = null
      n.cohort_features = []
      for (const f of this.availableFeatures) {
        n.cohort_features.push({
          id: f.id,
          name: f.name,
          feature_override_setting: 'no_change',
          hyper_parameters: JSON.stringify({})
        })
      }
      this.item.cohorts.push(n)
    },
    removeCohort (index) {
      this.item.cohorts.splice(index, 1)
    },
    async updateExperiment () {
      const generalExperimentsSettingsValid = await this.validateGeneralExperimentsSettings()
      if (!generalExperimentsSettingsValid) {
        this.errorMessage = 'invalid general settings'
        return null
      }
      const retailersExperimentsSettingsValid = await this.validateRetailersExperimentsSettings()
      if (!retailersExperimentsSettingsValid) {
        this.errorMessage = 'invalid retailers settings'
        return null
      }
      const cohortsExperimentsSettingsValid = await this.validateCohortsExperimentsSettings()
      if (!cohortsExperimentsSettingsValid) {
        this.errorMessage = 'invalid cohorts settings'
        return null
      }
      this.submit()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.notification {
  margin-left: 0;
  margin-right: 0;
}
/deep/ .timing .datepicker input.input {
  color: #E0E6EB !important;
  cursor: not-allowed !important;
}
/deep/ .timing .select select{
  color: #E0E6EB !important;
  cursor: not-allowed !important;
}
</style>
